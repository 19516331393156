<template>
  <div class="ly_news_cb_main">
      <div class="ly_news_cb_title">菌种代培养</div>
      <div class="ly_news_cb_hot_so">1、服务简介</div>
      <p>BNCC是一家专业的微生物菌种保藏中心，拥有完备的菌种培养与保藏设施。为满足客户需求，BNCC可对外提供微生物菌株的代培养与保藏形式的产品定制服务。可提供的保藏形式产品有：冻干保藏菌株、冻存保藏菌株、磁珠保藏菌株。</p>
      <div class="ly_news_cb_hot_so">2、流程</div>
      <img style="width:100%" src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/f894bc8fcf904885da3eb0ccb7998cb1.png" alt="流程">
      <div class="ly_news_cb_hot_so">3、注意事项</div>
      <p>（1）请在附件<a href="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/107eef698e56d20190479a19328ffa2a.xls"  rel="nofollow">《BNCC菌种代培养服务订单》</a>中详细填写样本信息；</p>
      <p>（2）本实验室只接收生物安全等级为1级和2级的微生物，不接收高致病性微生物；</p>
      <p>（3）定制后的产品的运输方式及费用请与业务人员进行咨询。</p>
      <p>（4）菌种代培养需5至10个工作日。</p>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download" @click="onBack()" style="background-color: #df0024">
        <a href="javascript:;"> 返回 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import {
  defineComponent
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "identiFication",
  setup() {
    const router = useRouter();
    function onBack() {
      router.back()
    }
    return {
      onBack
    };
  },
});
</script>


<style lang="scss" scoped>
.ly_news_cb_main {
  padding: 15px;
  font-size: 0.42667rem;
  line-height: 0.66667rem;
  color: #666;
  padding-bottom: 1.33333rem;
}
a{
    color:#05b3d2;
}
p {
  margin: 1em 0;
  word-break: break-word;
  text-indent: 20px;
}

.ly_news_cb_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #444;
}

.ly_news_cb_hot_so {
  font-weight: 600;
  color: #444;
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 10px;
}
footer .button a {
  color: #fff;
}
.download {
  float: right;
}
</style>